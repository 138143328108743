<template>
  <div class="table-notfound__container mt-6 p-10 mx-auto max-w-lg rounded-2xl text-center bg-gray-200 item-not-found">
    <div>
      <IconNotFound />
    </div>
    <h4 class="mt-6 pre-line">
      {{ content }}
    </h4>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconNotFound from '@/components/svg/IconNotFound.vue'

@Options({
  components: { IconNotFound },
  name: 'ItemNotFound',
})
export default class ItemNotFound extends Vue {
  @Prop({ type: String }) readonly content!: string
}
</script>
<style lang="scss">
.pre-line {
  white-space: pre-line;
}
</style>
