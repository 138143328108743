<template>
  <div class="edit-view display-in-center view full-height" v-loading.fullscreen="loading">
    <div class="title-label">
      <span>
        {{ getTitleLabel }}
      </span>
      <span> {{ getSearchedValue }} </span>
    </div>
    <div v-if="displayBarcodes && displayBarcodes.length > 0" class="barcode-list-container">
      <div class="overflow-y-auto border-gray-300 rounded-lg h-4/6">
        <div v-for="bc in displayBarcodes" :key="bc.id">
          <BarcodeLabel
            :label="bc.label || bc?.id || ''"
            :subLabel="bc.id"
            :type="bc.barcodeType"
            :labelClickable="true"
            :subLabelClickable="true"
            :labelOnly="false"
            :barcodeTypeDefinition="bc?.barcodeTypeDefinition"
            @click="onBarcodeItemClicked(bc?.id)"
          >
          </BarcodeLabel>
        </div>
      </div>

      <el-pagination
        layout="prev, pager, next"
        :total="totalBarcodes"
        :page-size="getBarcodePerPageSize"
        :pager-count="5"
        :current-page="currentPageNum"
        @current-change="currentPageChange($event)"
        background
        class="custom-pagination"
      />
    </div>
    <div v-else>
      <ItemNotFound class="mt-4" :content="$t('Barcode not found')" />
    </div>
  </div>
</template>
<script lang="ts">
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import BarcodeKeyValueInfoMixin from '@/components/mixins/BarcodeKeyValueInfoMixin.vue'
import IconDelete from '@/components/svg/IconDelete.vue'
import { SAVE_BARCODE_SEARCH_PARAMS } from '@/store/actions'
import { getBarcodeList } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { addLabelToBarcode } from '@/utils/helpers'
import { isEmpty } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  IBarcode,
  IBarcodeSearchForm,
  IBarcodeSearchFormItem,
  IDisplayBarcodes,
  IProject,
  TError,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'

@Options({
  components: {
    BarcodeLabel,
    IconDelete,
    ItemNotFound,
  },
  name: 'BarcodeListPage',
})
export default class BarcodeListPage extends mixins(BarcodeKeyValueInfoMixin) {
  loading = false
  barcodes = [] as IBarcode[]
  barcodeDialogVisible = false
  totalBarcodes = 0
  barcode = {} as IBarcode
  currentPageNum = 1

  dataSearch: {
    condition: IBarcodeSearchForm
  } = {
    condition: {
      projectCode: '',
      searchConditionBlocks: [],
      sortKey: '',
      sortOrder: 0,
      version: 0,
    },
  }

  get getTitleLabel() {
    return this.dataSearch?.condition?.searchConditionBlocks[0]?.searchConditions?.[1]?.key.includes('trackingNumber')
      ? this.$t('Tracking Number')
      : this.$t('externalId')
  }

  get getSearchedValue() {
    return this.dataSearch?.condition?.searchConditionBlocks[0]?.searchConditions?.[1]?.value || ''
  }

  get getBarcodePerPageSize() {
    return Math.round((window.innerHeight - 100) / 100)
  }

  onBarcodeItemClicked(id: string) {
    this.$router.push({
      name: 'detail',
      params: { project: this.dataSearch?.condition?.projectCode, barcodeId: id },
    })
  }

  async currentPageChange(pageNum: number) {
    try {
      this.loading = true
      this.currentPageNum = pageNum
      localStorage.setItem('currentPageNum', pageNum.toString())
      const skipNum = (Number(pageNum) - 1) * Number(this.dataSearch.condition.count)
      this.dataSearch.condition.skip = skipNum
      await this.fetchBarcodeList(this.dataSearch)
      addLabelToBarcode(this.barcodes, this.project)
    } catch (e) {
      errorHandler(e as TError)
    } finally {
      this.loading = false
    }
  }

  async fetchBarcodeList(searchPayload: { condition: IBarcodeSearchForm }) {
    this.dataSearch = searchPayload
    const data = localStorage.getItem('data')
    const currentPageNum = Number(localStorage.getItem('currentPageNum'))
    let searchResult = []

    if (
      data &&
      !this.$route.params.searchCondition &&
      isEqual(searchPayload, this.$store.getters.searchCondition) &&
      currentPageNum !== this.currentPageNum
    ) {
      searchResult = JSON.parse(data)
      this.currentPageNum = currentPageNum
    } else {
      searchResult = cloneDeep(await getBarcodeList(searchPayload))

      localStorage.setItem('data', JSON.stringify(searchResult))
      localStorage.setItem('currentPageNum', this.currentPageNum.toString())
    }

    const { results, count } = searchResult
    this.barcodes = results || []
    this.totalBarcodes = count
  }

  processQueryParams({
    projectCode,
    version,
    logicalOperator,
    skip,
    count,
    sortOrder,
    sortKey,
    ...others
  }: Record<string, string>) {
    const numberOfField = Object.keys(others).length / 3
    const searchConditions = []
    for (let i = 0; i < numberOfField; i++) {
      const key = others?.[`key_${i}`]
      const valueType = others?.[`valueType_${i}`]
      const value = others?.[`value_${i}`]
      searchConditions.push({ key, valueType, value } as IBarcodeSearchFormItem)
    }

    return {
      projectCode,
      version,
      isArchived: false,
      logicalOperator,
      skip,
      count,
      sortOrder,
      sortKey,
      searchConditionBlocks: [
        {
          searchConditions: searchConditions,
          logicalOperator: 'and',
        },
      ],
    }
  }

  async created() {
    if (!isEmpty(this.$route.query)) {
      this.$store.commit(SAVE_BARCODE_SEARCH_PARAMS, { condition: this.processQueryParams(this.$route.query) })
    }

    try {
      this.loading = true

      // get state from store
      const initSearchPayload = this.$route.params.searchCondition
        ? JSON.parse(this.$route.params.searchCondition)
        : !isEmpty(this.$store.getters.searchCondition)
        ? cloneDeep(this.$store.getters.searchCondition)
        : this.$router.go(-1)

      if (!initSearchPayload) return

      await this.fetchBarcodeList(initSearchPayload)

      addLabelToBarcode(this.barcodes, this.project)
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  get displayBarcodes() {
    const barcodes = cloneDeep(this.barcodes) as IDisplayBarcodes[]
    return barcodes || []
  }

  get barcodeName() {
    return this.project?.barcodeTypes?.[this.barcodes[0]?.barcodeType]?.name || ''
  }

  get nextSelectedTrackingPoint(): string {
    return this.extParams?.selectedNextTrackpoint as string
  }

  get project(): IProject {
    return this.$store.state.project.details
  }

  get currentTrackPointKey() {
    return this.barcodes[0]?.currentTrackPointKey
  }

  get currentTrackPointName() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name
  }

  get title(): string {
    return this.$route.params.title ?? this.$t('scanned_barcode_list')
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.title-label {
  padding: 8px 16px;
  text-align: center;
  border: 1px solid $border-color;
  border-radius: 15px;
  box-sizing: border-box;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}

.barcode-list-container {
  position: relative;
  margin-bottom: 40px;
}

.barcode-list-container:deep() {
  &.el-pagination {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);

    button {
      margin: 0 2px;
    }

    ul {
      li {
        margin: 0 3px;
      }
    }
  }
}
</style>
