<template>
  <SvgBase iconName="Delete" width="29" height="29" fill="none">
    <path
      d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z"
      fill="#979797"
    />
    <path
      d="M18.8499 18.8504L10.1499 10.1504M18.8499 10.1504L10.1499 18.8504"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconDelete extends Vue {}
</script>
